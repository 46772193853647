<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card flat class="mx-6">
      <v-card-title>
        用户角色管理
        <AppTooltipBtn
          rounded
          btn-class="ml-10"
          color="primary"
          icon="mdi-badge-account-horizontal-outline"
          label="创建新角色"
          tooltip="创建一个新的用户角色"
          @click="showRoleEditDialog(true)"
        />
        <AppTooltipBtn
          text
          btn-class="ml-4"
          color="primary"
          icon="mdi-refresh"
          label="刷新"
          tooltip="刷新列表"
          @click="refreshRolesWithPermission()"
        />
        <v-spacer></v-spacer>
        <v-text-field
          class="perm-search-field"
          v-model="permSearchText"
          append-icon="mdi-magnify"
          label="搜索"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <v-select
        v-if="isSuperNode"
        class="mx-10"
        label="选择要配置的节点"
        :items="allNodeListForSuper"
        v-model="currentNodeGuidForSuper"
      ></v-select>
      <v-data-table
        class="mt-4 mb-8"
        :items="rolePermissionList"
        item-key="guid"
        :headers="tableHeaders"
        :search="permSearchText"
        loading-text="正在读取用户角色列表，请稍候..."
        no-data-text="无用户角色，点击“创建”添加"
        no-results-text="未找到匹配的用户角色"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:[`item.permissions`]="{ value }">
          <div>
            <v-chip
              v-for="perm in value"
              :key="perm.guid"
              class="mr-1 my-1"
              small
            >
              {{ perm.dispName }}
            </v-chip>
          </div>
        </template>
        <template v-slot:[`item.guid`]="{ item }">
          <AppTooltipBtn
            btn-class="mr-2"
            color="primary"
            icon="mdi-pencil"
            tooltip="修改角色"
            @click="showRoleEditDialog(false, item)"
          />
          <AppTooltipBtn
            color="error"
            icon="mdi-delete"
            tooltip="删除角色"
            @click="showRoleDeleteDialog(item)"
          />
        </template>
      </v-data-table>
    </v-card>
    <AppDialog
      v-model="isShowEditRoleDialog"
      persistent
      size="small"
      overflow-height="calc(100vh - 300px)"
      :title="editorDialogTitle"
      color="primary"
      action-text="确定"
      :loading="isDialogLoading"
      @confirm="saveRoleInfoConfirmed"
      @closed="roleEditDialogClosed"
    >
      <v-text-field
        label="角色名称"
        v-model="actionRoleDispName"
        :rules="fieldRules.roleDispName"
      ></v-text-field>
      <v-text-field label="描述" v-model="actionRoleDescription"></v-text-field>
      <v-select
        label="拥有的权限"
        v-model="actionPermGuidList"
        :items="selectablePermissionList"
        item-text="dispName"
        item-value="guid"
        multiple
        chips
        deletable-chips
        :rules="fieldRules.permGuidList"
      ></v-select>
    </AppDialog>
    <AppDialog
      v-model="isShowDeleteRoleDialog"
      size="small"
      :title="deleteDialogTitle"
      color="red"
      action-text="删除"
      :loading="isDialogLoading"
      @confirm="roleDeleteConfirmed"
    >
      关联此角色的用户将无法登录！
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppDialog from "@/components/AppDialog";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import {
  getAllRolesFromNodeList,
  getAllPermissionsFromNodeList,
  createOneRole,
  updateOneRoleInfo,
  deleteOneRole
} from "@/api/role";
import { fetchClientNodesFromSuper } from "@/api/manageNode";
import { mapGetters } from "vuex";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppDialog,
    AppTooltipBtn
  },

  data() {
    return {
      isLoading: false,
      isDialogLoading: false,
      errorMsg: "",
      allNodeListForSuper: [],
      currentNodeGuidForSuper: "",
      rolePermissionList: [],
      selectablePermissionList: [],
      permSearchText: "",
      actionRoleGuid: "",
      actionRoleDispName: "",
      actionRoleDescription: "",
      actionPermGuidList: [],
      tableHeaders: [
        {
          text: "角色名称",
          value: "dispName"
        },
        {
          text: "描述",
          value: "description"
        },
        {
          text: "拥有的权限",
          value: "permissions",
          filterable: false
        },
        {
          text: "操作",
          value: "guid",
          sortable: false,
          filterable: false
        }
      ],
      // dialog
      isCreatingNewRole: false,
      isShowEditRoleDialog: false,
      isShowDeleteRoleDialog: false,
      // rules
      fieldRules: {
        roleDispName: [val => (val || "").length > 0 || "角色名称不能为空"],
        permGuidList: [val => (!!val && !!val.length) || "拥有的权限不能为空"]
      }
    };
  },

  watch: {
    currentNodeGuidForSuper() {
      this.refreshRolesWithPermission();
    }
  },

  computed: {
    ...mapGetters({
      nodeGuids: "user/loginNodeGuids",
      isSuperNode: "user/isSuperNode"
    }),
    activeNodeGuids() {
      if (this.isSuperNode && this.currentNodeGuidForSuper) {
        return [this.currentNodeGuidForSuper];
      }
      return this.nodeGuids;
    },
    editorDialogTitle() {
      if (this.isCreatingNewRole) {
        return "新建用户角色";
      } else {
        return "修改用户角色";
      }
    },
    deleteDialogTitle() {
      return `确定要删除用户角色${this.actionRoleDispName}吗？`;
    }
  },

  methods: {
    async fetchAllNodesForSuper() {
      if (this.isSuperNode) {
        try {
          this.isLoading = true;
          this.allNodeListForSuper = await fetchClientNodesFromSuper(
            this.nodeGuids[0]
          );
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isLoading = false;
      }
    },
    async refreshRolesWithPermission() {
      // 如果是 SuperNode，但是没有选择 ClientNode，则不显示列表
      if (this.isSuperNode && !this.currentNodeGuidForSuper) {
        return;
      }
      try {
        this.isLoading = true;
        this.rolePermissionList = await getAllRolesFromNodeList(
          this.activeNodeGuids
        );
        this.selectablePermissionList = await getAllPermissionsFromNodeList(
          this.activeNodeGuids
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    assignActionRoleObj(roleItem) {
      if (roleItem) {
        this.actionRoleGuid = roleItem.guid || "";
        this.actionRoleDispName = roleItem.dispName || "";
        this.actionRoleDescription = roleItem.description || "";
        this.actionPermGuidList =
          roleItem.permissions && roleItem.permissions.length
            ? roleItem.permissions.map(p => p.guid)
            : [];
      }
    },
    resetActionRoleObj() {
      this.actionRoleDispName = "";
      this.actionRoleDescription = "";
      this.actionPermGuidList = [];
    },
    // =================== Role Edit =======================
    showRoleEditDialog(isNewRole, selectedItem) {
      this.isCreatingNewRole = isNewRole;
      this.assignActionRoleObj(selectedItem);
      this.isShowEditRoleDialog = true;
    },
    roleEditDialogClosed() {
      this.resetActionRoleObj();
    },
    async saveRoleInfoConfirmed() {
      if (
        this.actionRoleDispName &&
        this.actionPermGuidList &&
        this.actionPermGuidList.length
      ) {
        if (this.isCreatingNewRole) {
          await this.insertOneRole();
        } else {
          await this.updateOneRole();
        }
        // 刷新
        this.refreshRolesWithPermission();
        this.isShowEditRoleDialog = false;
      }
    },
    async insertOneRole() {
      try {
        this.isDialogLoading = true;
        await createOneRole(this.activeNodeGuids, {
          roleDispName: this.actionRoleDispName,
          roleDescription: this.actionRoleDescription,
          permissionGuidList: this.actionPermGuidList
        });
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDialogLoading = false;
    },
    async updateOneRole() {
      try {
        this.isDialogLoading = true;
        await updateOneRoleInfo({
          roleGuid: this.actionRoleGuid,
          roleDispName: this.actionRoleDispName,
          roleDescription: this.actionRoleDescription,
          permissionGuidList: this.actionPermGuidList
        });
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDialogLoading = false;
    },
    // =================== Role Delete =======================
    showRoleDeleteDialog(selectedItem) {
      this.assignActionRoleObj(selectedItem);
      this.isShowDeleteRoleDialog = true;
    },
    async roleDeleteConfirmed() {
      try {
        this.isDialogLoading = true;
        await deleteOneRole(this.actionRoleGuid);
        // 刷新
        this.refreshRolesWithPermission();
        this.isShowDeleteRoleDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDialogLoading = false;
    }
  },

  async created() {
    await this.fetchAllNodesForSuper();
    await this.refreshRolesWithPermission();
  }
};
</script>

<style lang="scss" scoped>
.perm-search-field {
  max-width: 300px;
}
</style>
