<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card flat class="mx-6">
      <v-card-title>
        登录账号管理
        <AppTooltipBtn
          rounded
          btn-class="ml-10"
          color="primary"
          icon="mdi-account-plus"
          label="创建登录账号"
          tooltip="创建一个新的账号"
          @click="showAdminUserEditDialog(true)"
        />
        <AppTooltipBtn
          text
          btn-class="ml-4"
          color="primary"
          icon="mdi-refresh"
          label="刷新"
          tooltip="刷新列表"
          @click="refreshPage()"
        />
        <v-spacer></v-spacer>
        <v-text-field
          class="admin-user-search-field"
          v-model="adminUserSearchText"
          append-icon="mdi-magnify"
          label="搜索"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-card-title>
      <v-select
        v-if="isSuperNode"
        class="mx-10"
        label="选择要配置的节点"
        :items="allNodeListForSuper"
        v-model="currentNodeGuidForSuper"
      ></v-select>
      <v-data-table
        class="mt-4 mb-8"
        :items="adminUserList"
        item-key="userGuid"
        :headers="tableHeaders"
        :search="adminUserSearchText"
        loading-text="正在读取登录账号列表，请稍候..."
        no-data-text="无登录账号，点击“创建”添加"
        no-results-text="未找到匹配的登录账号"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:[`item.roleGuid`]="{ item }">
          <span>{{ getRoleFromRoleList(item.roleGuid) }}</span>
        </template>
        <template v-slot:[`item.nodeGuidList`]="{ value }">
          <div>
            <v-chip
              v-for="(nodeGuid, idx) in value"
              :key="idx"
              class="mr-1 my-1"
              small
            >
              {{ getRelNodeNameFromGuid(nodeGuid) }}
            </v-chip>
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <AppTooltipBtn
            btn-class="mr-2"
            color="primary"
            icon="mdi-pencil"
            tooltip="修改账号"
            @click="showAdminUserEditDialog(false, item)"
          />
          <AppTooltipBtn
            btn-class="mr-2"
            color="primary"
            icon="mdi-account-key"
            tooltip="修改密码"
            @click="showEditPasswordDialog(item)"
          />
          <AppTooltipBtn
            v-if="loggedInUserGuid !== item.userGuid"
            color="error"
            icon="mdi-delete"
            tooltip="删除账号"
            @click="showAdminUserDeleteDialog(item)"
          />
        </template>
      </v-data-table>
    </v-card>
    <AppDialog
      v-model="isShowEditAdminUserDialog"
      persistent
      size="small"
      overflow-height="calc(100vh - 300px)"
      :title="editorDialogTitle"
      color="primary"
      action-text="确定"
      :loading="isDialogLoading"
      @confirm="saveAdminUserInfoConfirmed"
      @closed="saveAdminUserInfoClosed"
    >
      <v-text-field
        label="显示名称"
        v-model="actionAdminUser.userName"
      ></v-text-field>
      <v-text-field
        label="登录名"
        v-model="actionAdminUser.loginKey"
        :rules="fieldRules.loginKey"
      ></v-text-field>
      <v-text-field
        v-if="isCreatingNewAdminUser"
        type="password"
        label="密码"
        v-model="actionAdminUser.userNewPwd"
        :rules="fieldRules.userNewPwd"
      ></v-text-field>
      <v-text-field
        label="描述"
        v-model="actionAdminUser.description"
      ></v-text-field>
      <v-select
        label="角色"
        v-model="actionAdminUser.roleGuid"
        :items="roleList"
        item-text="dispName"
        item-value="guid"
        :rules="fieldRules.roleGuid"
      ></v-select>
      <v-select
        label="登录的测评单位"
        v-model="actionAdminUser.nodeGuidList"
        :items="selectableNodeList"
        item-text="text"
        item-value="value"
        multiple
        chips
        deletable-chips
        :rules="fieldRules.nodeGuidList"
      ></v-select>
    </AppDialog>
    <CredentialsPasswordDialog
      v-model="isShowChangePasswordDialog"
      :user-guid="actionAdminUser.userGuid"
    />
    <AppDialog
      v-model="isShowDeleteAdminUserDialog"
      size="small"
      :title="deleteDialogTitle"
      color="red"
      action-text="删除"
      :loading="isDialogLoading"
      @confirm="adminUserDeleteConfirmed"
    >
      删除后将无法使用该用户登录！
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppDialog from "@/components/AppDialog";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import CredentialsPasswordDialog from "@/components/CredentialsPasswordDialog";
import {
  fetchAllAdminUserList,
  createOneAdminUser,
  updateAdminUserInfo,
  deleteOneAdminUser
} from "@/api/user";
import {
  fetchManageNodeFlatSelectList,
  fetchClientNodesFromSuper
} from "@/api/manageNode";
import { getAllRolesFromNodeList } from "@/api/role";
import { mapGetters } from "vuex";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppDialog,
    AppTooltipBtn,
    CredentialsPasswordDialog
  },

  data() {
    return {
      isLoading: false,
      isDialogLoading: false,
      errorMsg: "",
      allNodeListForSuper: [],
      currentNodeGuidForSuper: "",
      adminUserList: [],
      adminUserSearchText: "",
      actionAdminUser: {
        userGuid: "",
        userName: "",
        loginKey: "",
        userNewPwd: "",
        description: "",
        roleGuid: "",
        nodeGuidList: []
      },
      selectableNodeList: [],
      roleList: [],
      tableHeaders: [
        {
          text: "显示名称",
          value: "userName"
        },
        {
          text: "登录名",
          value: "loginKey"
        },
        {
          text: "描述",
          value: "description"
        },
        {
          text: "角色",
          value: "roleGuid"
        },
        {
          text: "登录的测评单位",
          value: "nodeGuidList",
          filterable: false
        },
        {
          text: "操作",
          value: "actions",
          sortable: false,
          filterable: false
        }
      ],
      fieldRules: {
        userNewPwd: [val => (val || "").length >= 6 || "新密码不能少于6位"],
        loginKey: [val => (val || "").length > 0 || "登录名不能为空"],
        roleGuid: [val => (val || "").length > 0 || "角色不能为空"],
        nodeGuidList: [val => (!!val && !!val.length) || "角色不能为空"]
      },
      // dialog
      isShowEditAdminUserDialog: false,
      isCreatingNewAdminUser: false,
      isShowDeleteAdminUserDialog: false,
      isShowChangePasswordDialog: false
    };
  },

  watch: {
    currentNodeGuidForSuper() {
      this.refreshAdminUserList();
    }
  },

  computed: {
    ...mapGetters({
      regionGuid: "user/regionGuid",
      nodeGuids: "user/loginNodeGuids",
      isSuperNode: "user/isSuperNode",
      loggedInUserGuid: "user/userGuid"
    }),
    activeNodeGuids() {
      if (this.isSuperNode && this.currentNodeGuidForSuper) {
        return [this.currentNodeGuidForSuper];
      }
      return this.nodeGuids;
    },
    editorDialogTitle() {
      if (this.isCreatingNewAdminUser) {
        return "新建账号";
      } else {
        return "修改账号";
      }
    },
    deleteDialogTitle() {
      return `确定要删除账号${this.actionAdminUser.loginKey}吗？`;
    }
  },

  methods: {
    showAdminUserEditDialog(isNewCred, selectedItem) {
      this.isCreatingNewAdminUser = isNewCred;
      this.actionAdminUser = selectedItem || {};
      this.isShowEditAdminUserDialog = true;
    },
    showEditPasswordDialog(selectedItem) {
      this.actionAdminUser = selectedItem || {};
      this.isShowChangePasswordDialog = true;
    },
    showAdminUserDeleteDialog(selectedItem) {
      this.actionAdminUser = selectedItem || {};
      this.isShowDeleteAdminUserDialog = true;
    },
    getRoleFromRoleList(roleGuid) {
      if (this.roleList && this.roleList.length) {
        let roleFound = this.roleList.find(r => r.guid === roleGuid);
        if (roleFound) {
          return roleFound.dispName;
        }
      }
      return "";
    },
    getRelNodeNameFromGuid(nodeGuid) {
      if (this.selectableNodeList && this.selectableNodeList.length) {
        let nodeFound = this.selectableNodeList.find(n => n.value === nodeGuid);
        if (nodeFound) {
          return nodeFound.text;
        }
      }
    },
    async refreshPage() {
      await this.fetchAllNodesForSuper();
      await this.refreshAdminUserList();
    },
    async fetchAllNodesForSuper() {
      if (this.isSuperNode) {
        try {
          this.isLoading = true;
          this.allNodeListForSuper = await fetchClientNodesFromSuper(
            this.nodeGuids[0]
          );
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isLoading = false;
      }
    },
    async refreshAdminUserList() {
      // 如果是 SuperNode，但是没有选择 ClientNode，则不显示列表
      if (this.isSuperNode && !this.currentNodeGuidForSuper) {
        return;
      }
      try {
        this.isLoading = true;
        this.roleList = await getAllRolesFromNodeList(this.activeNodeGuids);
        this.selectableNodeList = await fetchManageNodeFlatSelectList(
          this.regionGuid,
          this.activeNodeGuids
        );
        this.adminUserList = [];
        this.adminUserList = await fetchAllAdminUserList(
          this.regionGuid,
          this.activeNodeGuids
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async insertOneAdminUser() {
      try {
        this.isDialogLoading = true;
        let newCredObj = {
          regionGuid: this.regionGuid,
          userName: this.actionAdminUser.userName,
          loginKey: this.actionAdminUser.loginKey,
          password: this.actionAdminUser.userNewPwd,
          description: this.actionAdminUser.description,
          roleGuid: this.actionAdminUser.roleGuid,
          nodeGuidList: this.actionAdminUser.nodeGuidList
        };
        let newCredGuid = await createOneAdminUser(newCredObj);
        this.adminUserList.push({
          userGuid: newCredGuid,
          userName: newCredObj.userName,
          loginKey: newCredObj.loginKey,
          roleGuid: newCredObj.roleGuid,
          nodeGuidList: newCredObj.nodeGuidList
        });
        this.isShowEditAdminUserDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDialogLoading = false;
    },
    async updateOneAdminUser() {
      try {
        this.isDialogLoading = true;
        await updateAdminUserInfo(this.actionAdminUser.userGuid, {
          regionGuid: this.regionGuid,
          userName: this.actionAdminUser.userName,
          loginKey: this.actionAdminUser.loginKey,
          description: this.actionAdminUser.description,
          roleGuid: this.actionAdminUser.roleGuid,
          nodeGuidList: this.actionAdminUser.nodeGuidList
        });
        this.isShowEditAdminUserDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDialogLoading = false;
    },
    saveAdminUserInfoConfirmed() {
      if (
        this.actionAdminUser.loginKey &&
        this.actionAdminUser.roleGuid &&
        this.actionAdminUser.nodeGuidList &&
        this.actionAdminUser.nodeGuidList.length
      ) {
        if (this.isCreatingNewAdminUser) {
          return this.insertOneAdminUser();
        } else {
          return this.updateOneAdminUser();
        }
      }
    },
    saveAdminUserInfoClosed() {
      this.actionAdminUser = {};
    },
    async adminUserDeleteConfirmed() {
      let actionCredGuid = this.actionAdminUser.userGuid;
      if (this.loggedInUserGuid !== actionCredGuid) {
        try {
          this.isDialogLoading = true;
          await deleteOneAdminUser(actionCredGuid);
          let deletingIndex = this.adminUserList.findIndex(
            cr => cr.userGuid === actionCredGuid
          );
          if (deletingIndex > -1) {
            this.adminUserList.splice(deletingIndex, 1);
          }
          this.isShowDeleteAdminUserDialog = false;
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isDialogLoading = false;
      } else {
        this.errorMsg = "不能删除自己的账号";
      }
    }
  },

  created() {
    this.refreshPage();
  }
};
</script>

<style lang="scss" scoped>
.admin-user-search-field {
  max-width: 300px;
}
</style>
