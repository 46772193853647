<template>
  <v-container>
    <AppLoadingSpinner v-model="isLoading" />
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-tabs show-arrows>
            <v-tab v-if="shouldShowCustomerInfoSettings">基本设置</v-tab>
            <v-tab>登录设置</v-tab>
            <v-tab>角色设置</v-tab>
            <v-tab-item
              v-if="shouldShowCustomerInfoSettings"
              class="fixed-tab-items-right"
            >
              <AdminSettingsInfo />
            </v-tab-item>
            <v-tab-item class="fixed-tab-items-right">
              <CredentialsEditor class="credential-tab-item" />
            </v-tab-item>
            <v-tab-item class="fixed-tab-items-right">
              <RolePermissionEditor class="credential-tab-item" />
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </v-container>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AdminSettingsInfo from "@/components/admin/AdminSettingsInfo";
import CredentialsEditor from "@/components/CredentialsEditor";
import RolePermissionEditor from "@/components/RolePermissionEditor";
import { mapGetters } from "vuex";
import { fetchManageNodeTreeList } from "@/api/manageNode";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AdminSettingsInfo,
    CredentialsEditor,
    RolePermissionEditor
  },

  data() {
    return {
      isLoading: false,
      errorMsg: "",
      nodeTreeList: []
    };
  },

  computed: {
    ...mapGetters({
      regionGuid: "user/regionGuid",
      nodeGuids: "user/loginNodeGuids"
    }),
    hasTopMostNodes() {
      var topmostNodeIndex = this.nodeTreeList.findIndex(
        node => node.parentId < 1
      );
      return topmostNodeIndex > -1;
    },
    shouldShowCustomerInfoSettings() {
      return (
        this.nodeTreeList && this.nodeTreeList.length && this.hasTopMostNodes
      );
    }
  },

  methods: {
    async getNodeTreeList() {
      try {
        this.isLoading = true;
        this.nodeTreeList = await fetchManageNodeTreeList(
          this.regionGuid,
          this.nodeGuids
        );
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    }
  },

  created() {
    this.getNodeTreeList();
  }
};
</script>

<style lang="scss" scoped>
.fixed-tab-items-right {
  height: calc(100vh - 220px);
  overflow: auto;
}
.credential-tab-item {
  min-width: 700px;
}
</style>
